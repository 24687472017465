import { LifeCycles, registerApplication, start } from "single-spa";

interface ApplicationConfig {
	IS_HEALTHPROBES_ACTIVE: boolean;
	IS_VERSIONPAGE_ACTIVE: boolean;
	IS_VCS_ACTIVE: boolean;
}

let config: ApplicationConfig;

// eslint-disable-next-line prefer-const
config = JSON.parse(process.env.REACT_APP_APPLICATION_LIST ?? "");

registerApplication({
	name: "@arcane/dashboard",
	app: () => System.import<LifeCycles>("@arcane/dashboard"),
	activeWhen: ["/"],
	customProps: {
		applications: process.env.REACT_APP_APPLICATION_LIST,
	},
});

if (config.IS_HEALTHPROBES_ACTIVE) {
	registerApplication({
		name: "@arcane/health-probes",
		app: () => System.import<LifeCycles>("@arcane/health-probes"),
		activeWhen: ["/health-probes"],
		customProps: {
			applications: process.env.REACT_APP_APPLICATION_LIST,
		},
	});
}

if (config.IS_VERSIONPAGE_ACTIVE) {
	registerApplication({
		name: "@arcane/version-page",
		app: () => System.import<LifeCycles>("@arcane/version-page"),
		activeWhen: ["/version-page"],
		customProps: {
			applications: process.env.REACT_APP_APPLICATION_LIST,
		},
	});
}
// registerApplication({
//   name: "@arcane/navbar",
//   app: () => System.import("@arcane/navbar"),
//   activeWhen: ["/"]
// });

start({
	urlRerouteOnly: true,
});
